// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

const Sentry = require('@sentry/nextjs');

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV;
const TRACE_SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_SENTRY_CLIENT_TRACE_SAMPLE_RATE) || 0

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV || '',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: TRACE_SAMPLE_RATE,
  // Error message consistently contains the keyword we're trying to filter out. these two errors are occurring on older version of browser.
  ignoreErrors: ['querySelectorAll', 'ttd_dom_ready']
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
