import CTA from 'components/UI/Cta';
import Layout from 'components/UI/Layout';
import { BrandedSiteProps } from 'lib/routing';
import { ReactElement } from 'react';

interface ErrorProps {
  httpCode: number;
  siteData: BrandedSiteProps;
  currentSlug: string;
}

function errorDetails(imageUrl: string, altText: string, text: string): ReactElement {
  return (
    <div className="flex flex-col justify-center items-center p-20">
      <img src={`/images/errors/${imageUrl}`} alt={altText} />
      <p className="my-10">{text}</p>
      <CTA text="Return Home" slug="" colorScheme="Primary" />
    </div>
  );
}

function Error({ httpCode, siteData, currentSlug }: ErrorProps): ReactElement {
  let title = 'Error';
  let text = 'Whoops, we’re having technical issues. Please reload the page, or return home';
  let imageUrl = '500.svg';
  let altText = '500 error';

  if (httpCode === 404) {
    text = 'Oh no! The page your looking for is unknown or does not exist.';
    imageUrl = '404.svg';
    altText = 'not found';
    title = 'Not Found';
  }

  if (siteData) {
    return (
      <Layout
        nextSeoProps={{ title, description: '' }}
        siteData={siteData}
        preview={false}
        currentSlug={currentSlug}
      >
        {errorDetails(imageUrl, altText, text)}
      </Layout>
    );
  }
  return <>{errorDetails(imageUrl, altText, text)}</>;
}

export default Error;
