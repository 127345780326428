import { AppProps } from 'next/app';
import Error from './_error';

import '../styles/global.css';

export default function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  if (pageProps.error) {
    return <Error {...pageProps.error} />;
  }
  return <Component {...pageProps} />;
}
